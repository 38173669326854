import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import cookieHealthCheck from "@/hooks/api/cookie/healthCheckCookie";
import createQuoteReference from "@/hooks/api/questions/createQuote";
import fetchQuoteData, { GetQuoteFilter } from "@/hooks/api/questions/getQuote";
import saveQuestionData from "@/hooks/api/questions/saveQuestion";
import updateQuoteReference from "@/hooks/api/questions/updateQuote";
import { QuestionPayload } from "@/types/QuestionPayloadType";
import { apiErrorMappings } from "@/utils/errors/api-error-map";

const getQuoteData = createAsyncThunk(
  "question/getQuote",
  // eslint-disable-next-line consistent-return
  async (getQuoteFilter: GetQuoteFilter, { rejectWithValue }) => {
    try {
      const quote: QuestionPayload | null | undefined =
        await fetchQuoteData(getQuoteFilter);
      return quote;
    } catch (err: unknown) {
      const errorObj = apiErrorMappings(err as AxiosError);
      if (errorObj?.errors?.[0]?.code === 4029) return;
      if (errorObj.httpCode !== 401) errorObj.multiButton = true;
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(errorObj);
    }
  }
);

const createQuoteOnQuestion = createAsyncThunk(
  "question/createQuote",
  async (payload: QuestionPayload, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const quote: QuestionPayload | null | undefined =
        await createQuoteReference(payload);
      return quote;
    } catch (err: unknown) {
      const errorObj = apiErrorMappings(err as AxiosError);
      if (errorObj.httpCode !== 401) errorObj.multiButton = true;

      if (errorObj.httpCode === 400) {
        errorObj.validationEnable = true;
      }
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(errorObj);
    }
  }
);

const updateQuoteOnQuestion = createAsyncThunk(
  "question/updateQuote",
  async (payload: QuestionPayload, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const quote: QuestionPayload | null | undefined =
        await updateQuoteReference(payload);
      return quote;
    } catch (err: unknown) {
      const errorObj = apiErrorMappings(err as AxiosError);
      if (errorObj.httpCode !== 401) errorObj.multiButton = true;
      if (errorObj.httpCode === 400) {
        errorObj.validationEnable = true;
      }
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(errorObj);
    }
  }
);

const saveQuestionDataBFF = createAsyncThunk(
  "question/saveQuestionDataBFF",
  async (payload: QuestionPayload, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const quote: QuestionPayload | null | undefined =
        await saveQuestionData(payload);
      return quote;
    } catch (err: unknown) {
      const errorObj = apiErrorMappings(err as AxiosError);
      if (errorObj.httpCode !== 401) errorObj.multiButton = true;
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(errorObj);
    }
  }
);

const sessionCheck = createAsyncThunk(
  "question/sessionCheck",
  async (_, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const quote: QuestionPayload | null | undefined =
        await cookieHealthCheck();
      return quote;
    } catch (err: unknown) {
      const errorObj = apiErrorMappings(err as AxiosError);
      if (errorObj.httpCode !== 401) errorObj.multiButton = true;
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(errorObj);
    }
  }
);

export {
  getQuoteData,
  createQuoteOnQuestion,
  updateQuoteOnQuestion,
  saveQuestionDataBFF,
  sessionCheck,
};
