import { AxiosError } from "axios";

import { ApiErrorProps, ResponseData } from "../../types/ApiErrorType";

/**
 * HTTP error code
 * @type {Object}
 * @constant
 * @readonly
 * @public
 * @memberof utils/errors/api-error-map
 * @description API HTTP error codes
 *
 */

export const apiErrorCode: { [key: string]: string } = {
  "200": "Cookie Already Exists",
  "400": "Validation Failed",
  "401": "Cookie Expired",
  "403": "Invalid Request",
  "404": "Domain Not Found  ",
  "405": "Last Visited Page Not Found",
  "406": "Title Not Matched",
  "407": "First Name Not Valid",
  "408":
    "Please supply a valid name (only letters, space, apostrophe and hyphen are allowed)",
  "409": "LAST Name Not Valid",
  "410": "DOB Not Valid",
  "411": "Email Not Valid",
  "412": "Postcode Not Valid",
  "413": "Address1 Not Valid",
  "414": "Address2 Not Valid",
  "415": "City Not Valid",
  "416": "Country Not Valid",
  "417": "Please provide a valid telephone",
  "418": "Policy Type Not Valid",
  "419": "From Location Not Valid",
  "420": "To Location Not Valid",
  "421": "Start Date Not Valid",
  "422": "End Date Not Valid",
  "423": "Start Date Has To Be In The Future",
  "424": "Party Type Not Valid",
  "425": "Area Not Valid",
  "426": "Medical Terms And Conditions Not Valid",
  "427": "Age Not Valid",
  "428": "Sequence Not Valid",
  "429": "Full Time Education Not Valid",
  "430": "Data Not Found",
  "431": "Going On Cruise Not Valid",
  "432": "Traveller details node does not exist.",
  "433": "Medical request node does not exist.",
  "434": "Medical Request Not Valid",
  "435": "Attribute node does not exist.",
  "436": "Email Not Found",
  "437": "Telephone Not Found",
  "438": "Organizer Contacts Not Found",
  "439": "Marketing Consents Not Found",
  "440": "Invalid Data Format",
  "441": "Marketing Consents Missing",
  "500": "Unable to Proceed",
  "501": "Json Parse Error",

  "502": "Bad Gateway",
  "503": "Service Unavailable",
  "504": "Gateway Timeout",
};

/**
 * API error mappings
 * @function
 * @public
 * @memberof utils/errors/api-error-map
 * @param {AxiosError} error - Axios error
 * @returns {ApiErrorProps} - API error properties
 * @description API error mappings
 *
 */

export const apiErrorMappings = (error: AxiosError) => {
  const { response, code, message } = error;
  let errorObject = {
    httpCode: response?.status ? response.status : null,
    errorCode: code,
    errorMessage: response?.status
      ? apiErrorCode[response?.status.toString()]
      : message,
    errorStatus: true,
    errors: null,
  } as unknown as ApiErrorProps;

  const responseData = response?.data as ResponseData;

  if (responseData) {
    const { errors } = responseData;

    errorObject = {
      ...errorObject,
      errors,
    };
  }

  return errorObject;
};
