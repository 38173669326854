import axios, { InternalAxiosRequestConfig } from "axios";

import { BASE_URL, BFF_API_KEY } from "@/constants";

const baseURL = BASE_URL;
const isServer = typeof window === "undefined";

const axioInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": `${BFF_API_KEY}`,
  },
  withCredentials: true,
});

axioInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    if (isServer) {
      // Handle server side
    } else {
      // Handle client side
    }

    return config;
  }
);

axioInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      // Handle unauthorized access
    } else if (status === 404) {
      // Handle not found errors
    }

    // Handle other errors
    return Promise.reject(error);
  }
);

export default axioInstance;
