import axioInstance from "@/libs/axios";
import { QuestionPayload } from "@/types/QuestionPayloadType";

// const baseURL = "http://localhost:3000/api";
const updateQuoteReference = async (payload: QuestionPayload) => {
  let quoteResponse: QuestionPayload | null;

  const result = await axioInstance.patch(
    `/quote/${payload.referenceId}/initialQuestionSet`,
    {
      data: payload,
    }
  );
  // eslint-disable-next-line prefer-const
  quoteResponse = result?.data?.data;
  return quoteResponse;
};

export default updateQuoteReference;
