import axioInstance from "@/libs/axios";

// const baseURL = "http://localhost:3000/api";
// eslint-disable-next-line consistent-return
const cookieHealthCheck = async () => {
  const responseCookie = await axioInstance.get(`/sessionHealth`);
  const cookieData = responseCookie?.data;

  return cookieData;
};

export default cookieHealthCheck;
