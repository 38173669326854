import axioInstance from "@/libs/axios";
import { QuestionPayload } from "@/types/QuestionPayloadType";

// const baseURL = "http://localhost:3000/api";
const createQuoteReference = async (payload: QuestionPayload) => {
  const result = await axioInstance.post(`/initialQuestionSet`, {
    data: payload,
  });
  return result?.data?.data;
};

export default createQuoteReference;
