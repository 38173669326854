import axioInstance from "@/libs/axios";

export type GetQuoteFilter = {
  affiliate?: string | null | undefined;
  quoteId?: string | null | undefined;
  myAccountUserId?: string | null | undefined;
};
// eslint-disable-next-line consistent-return
const fetchQuoteData = async (getQuoteFilter: GetQuoteFilter) => {
  const { affiliate, quoteId } = getQuoteFilter;

  const url = `/quote${quoteId ? `/${quoteId}` : ""}${affiliate ? `?affiliate=${affiliate}` : ""}`;
  const result = await axioInstance.get(url);
  return result?.data?.data;
};

export default fetchQuoteData;
