import axioInstance from "@/libs/axios";
import { QuestionPayload } from "@/types/QuestionPayloadType";

// const baseURL = "http://localhost:3000/api";
const saveQuestionData = async (payload: QuestionPayload) => {
  // eslint-disable-next-line prefer-const
  const saveInitDataResponse = await axioInstance.post(`/saveInitData`, {
    data: payload,
  });

  return saveInitDataResponse?.data;
};

export default saveQuestionData;
